import * as React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import  events  from "./events";
import moment from 'moment'
import YearView from './YearView'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import './EventCalendar.css'
//import { useEffect } from 'react'
//import { useNavigate } from 'react-router-dom'

const EventCalendar = (props) => {
  
  //const { loggedIn } = props
  //const navigate = useNavigate()
  //console.log('props: '+JSON.stringify(props))
  //useEffect(() => { if (loggedIn) navigate('/login') })
  // Setup the localizer by providing the moment (or globalize) Object
  // to the correct localizer.
  const localizer = momentLocalizer(moment) // or globalizeLocalizer
  localizer.formats.yearHeaderFormat = 'YYYY'
  // localizer.messages.year = 'Year'
  
    return (
      <>
      <div className={props.window === false ? "calendarContainer": "calContainer"}>
        <Calendar 
          localizer={localizer}
          events={events||[]}
          startAccessor="start"
          endAccessor="end"
          defaultView="week"
          //toolbar={true}
          views={{
            day: true, 
            week: true, 
            month: false,
            year: YearView,
            agenda: true
          }}
          messages={{ year: 'Year' }}
        />
      </div>
      </>
    );
  }


export default EventCalendar;
