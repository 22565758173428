import React from 'react'
import { useNavigate } from 'react-router-dom'

const Home = (props) => {
  const { loggedIn, email } = props
  const navigate = useNavigate()
  const onButtonClick = () => {
    if (loggedIn) {
      localStorage.removeItem('user')
      props.setLoggedIn(false)
    } else {
      navigate('/login')
    }
  }

  return (
    <>
    <div className={props.window === false ? "mainContainer": "mnContainer"}>
      <div className={'titleContainer'}>
        <div>Welcome!</div>
      </div>
      {
        loggedIn ? 
          <div>
            You are logged in. <br/>
            Your email address is {email}
          </div> 
        : 
          <div className={'buttonContainer'}>
            <input
              className={'inputButton'}
              type="button"
              onClick={onButtonClick}
              value={'Log in'}
            />
          </div>
      }
    </div>
    </>
  )
}

export default Home