
const events = [
    {
      'title': 'All Day Event very long title',
      'allDay': true,
      'start': new Date(2024, 6, 0),
      'end': new Date(2024, 6, 1)
    },
    {
      'title': 'Residency Class',
      'start': new Date(2024, 6, 11),
      'end': new Date(2024, 6, 15)
    },
    {
      'title': 'Welcome Party',
      'start': new Date(2024, 6, 11, 0, 0, 0, 0),
      'end': new Date(2024, 6, 11, 5, 0, 0, 0),
      desc: 'Pre-meeting meeting, to prepare for the meeting'
    },
    {
      'title': 'Residency First Day',
      'start': new Date(2024, 6, 11, 9, 0, 0),
      'end': new Date(2024, 6, 11, 17, 0, 0)
    },
    {
      'title': 'Residency Second Day',
      'start': new Date(2024, 6, 12, 9, 0, 0),
      'end': new Date(2024, 6, 12, 17, 0, 0)
    },
    {
      'title': 'Dinner',
      'start': new Date(2024, 6, 12, 18, 0, 0, 0),
      'end': new Date(2024, 6, 12, 21, 0, 0, 0)
    },
    {
      'title': 'Meeting',
      'start': new Date(2024, 6, 13, 17, 30, 0, 0),
      'end': new Date(2024, 6, 13, 18, 30, 0, 0)
    },
    {
      'title': 'Residency Third Day',
      'start': new Date(2024, 6, 13, 9, 0, 0),
      'end': new Date(2024, 6, 13, 17, 0, 0)
    },
    {
      'title': 'Happy Hour',
      'start': new Date(2024, 6, 13, 19, 0, 0, 0),
      'end': new Date(2024, 6, 13, 22, 30, 0, 0),
      desc: 'Most important meal of the day'
    },
    {
      'title': 'Residency Last Day',
      'start': new Date(2024, 6, 14, 9, 0, 0),
      'end': new Date(2024, 6, 14, 13, 0, 0),
      desc: 'Residency class last day for students'
    },
    {
      'title': 'Lunch',
      'start': new Date(2024, 6, 14, 13, 30, 0, 0),
      'end': new Date(2024, 6, 14, 15, 0, 0, 0),
      desc: 'Power lunch'
    },
    {
      'title': 'Driving from Virginia To Charlotte Airport',
      'start': new Date(2024, 6, 14, 15, 30, 0),
      'end': new Date(2024, 6, 14, 19, 30, 0)
    },
    {
      'title': 'Flight From Charlotte To Chicago',
      'start': new Date(2024, 6, 14, 21, 0, 0),
      'end': new Date(2024, 6, 14, 23, 30, 0)
    },
    {
      'title': '[Day1: Home-Assignment] FrontEnd Development',
      'start': new Date(2024, 6, 15, 8, 0, 0),
      'end': new Date(2024, 6, 15, 17, 30, 0),
      desc: 'Use React.js to build a simple web page. Research Technologies & Decision of what I present. Coding for FrontEnd web.'
    },
    {
      'title': '[Day2: Home-Assignment] FrontEnd Data & Test',
      'start': new Date(2024, 6, 16, 8, 30, 0),
      'end': new Date(2024, 6, 16, 22, 0, 0),
      desc: 'Use Django to build at least two API endpoints for the frontend page you created to use.'
    },
    {
      'title': '[Day3: Home-Assignment] BackEnd Technology & Coding',
      'start': new Date(2024, 6, 17, 9, 30, 0),
      'end': new Date(2024, 6, 17, 22, 0, 0),
      desc: 'Basic requirements: Use AWS S3 to deploy your frontend code. Use AWS EC2 to deploy your backend code. Ensure the website is accessible publicly. Bonus requirements: Use AWS CloudFront for CDN. Create a custom VPC with one private subnet and put your EC2 in it.'
    },
    {
      'title': '[Day4: Home-Assignment] AWS Technology & Deploye & Presentation',
      'start': new Date(2024, 6, 18, 8, 0, 0),
      'end': new Date(2024, 6, 18, 22, 0, 0),
      desc: 'Include an overview of your tech stack and architecture. Explain the deployment process to AWS. Provide a live demo or screenshots of the running website and its key features.'
    },
    {
      'title': 'Multi-day Event',
      'start': new Date(2024, 6, 15, 7, 0, 0),
      'end': new Date(2024, 6, 19, 18, 0, 0),
      desc: '1.Develop a simple website; 2.Deploy the website to AWS;3.Provide a presentation of your work (PDF, PPT, online demo, etc.)'
    }
  ]

  export default events