import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import CustomNav from './Components/CustomNav';
import Home from './Components/Home'
import Login from './Components/Login'
import EventCalendar from './Components/EventCalendar';
import NoisyOrgans from './Components/NoisyOrgans'
//import Profile from './Components/Profile'
import { useEffect, useState } from 'react'

function App() {
  const [window, setWindow] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false)
  const [email, setEmail] = useState('')
  //const [isGuest, setIsGuest] = useState(false)

  useEffect(() => {
    // Fetch the user email and token from local storage
    const user = JSON.parse(localStorage.getItem('user'))
  
    // If the token/email does not exist, mark the user as logged out
    if (!user || !user.token) {
      setLoggedIn(false)
      return
    }
  
      // If the token exists, verify it with the auth server to see if it is valid
      fetch('http://back.zayavibes.com:8080/verify', {
          method: 'POST',
          headers: {
            'jwt-token': user.token,
          },
        })
        .then((r) => r.json())
        .then((r) => {
          setLoggedIn('success' === r.message)
          setEmail(user.email || '')
        })
        .catch((error) => {
          window.alert('Check BackEnd API. \nError Message: \n\n'+error)
        })
      }
    , [])
  
  return (
    <div className="App">
      {loggedIn ? 
        <CustomNav
          window={window} 
          setWindow={setWindow}
          loggedIn={loggedIn} 
          setLoggedIn={setLoggedIn}
          li={[
            ["Dashboard", "assets/dashboard.svg",'/'],
            ["Schedule", "assets/calendar.svg",'schedule'],
            //["Profile", "assets/user.svg",'profile'],
            ["Noisy Organs", "assets/noisy.svg",'noisyorgans'],
          ]}
        /> : ''}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home window={window} email={email} loggedIn={loggedIn} />} />
          <Route path="/login" element={<Login window={window} setLoggedIn={setLoggedIn} setEmail={setEmail} />} />
          <Route path="/schedule"  element={<EventCalendar window={window} />} />
          <Route path="/noisyorgans" element={<NoisyOrgans window={window} />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App;


