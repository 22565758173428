import React, {useState,useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import './NoisyOrgans.css'

const NoisyOrgans = (props) => {
  let { loggedIn } = props
  const navigate = useNavigate()
  console.log(loggedIn)
  useEffect(() => {
    if(loggedIn === false)
      navigate('/login')
  },[])
  const [showInternal, setShowInternal] = useState(false)
  const [showExternal, setShowExternal] = useState(false)
  const showInternalClick = () => {
    if(showInternal){
      setShowInternal(false);
    } else {
      setShowInternal(true);
    }
  }

  const showExternalClick = () => {
    if(showExternal){
      setShowExternal(false);
    } else {
      setShowExternal(true);
    }
  }

  return (
    <>
    <div className={props.window === false ? "mainContainer": "mnContainer"}>      
      <div id={"hero_section"} className="hero_section"> 
          <div className="hero_body">
            <img src={"assets/Title.png"} className="hero_title" alt='Title'/>
            <div className="hero_msg">
                Have you ever heard your body make some strange noises? 
                <br/>Have you ever wondered what those sounds are?
            </div>
          </div>
      </div>      
      <div className="frame_section0"> 
        <div className="frame_body">
          <div className="internal_link">
            <span className="btn_title">Internal Organ</span>
            <button 
              id="show_internal" 
              className="switch_button"
              onClick={showInternalClick}
              > {showInternal? 'Hide':'Explore'}
            </button>
          </div>
          <div className="external_link">
            <span className="btn_title">External Organ</span>
            <button id="show_external" className="switch_button"
              onClick={showExternalClick} 
              style={{color: '#FC7DA8'}}>
                {showExternal? 'Hide':'Explore'}
            </button>
          </div>
        </div> 
      </div>
      { showInternal? 
      <div id="internal_organs" style={{display:'block'}}>
        <div id="heart_frame" className="frame_section"> 
          <img className="frame_image" src="assets/HeartFrame.png" alt='HeartFrame'/>
          <div className="frame1_body">
            <p className="frame1_title">Heart</p>
              <p className="frame1_txt">
                Your heart is like a tiny drummer in your chest. 
                It beats rhythmically, sending blood all around your body. 
                That's why you can feel it go "thump-thump" sometimes, just like a little drummer playing a beat.
              </p>
          </div> 
        </div>
        <div id="lung_frame" className="frame_section"> 
          <img className="frame_image" src="assets/LungsFrame.png" alt="back_img2"/>
          <div className="frame2_body">
            <p className="frame2_title">Lungs</p>
              <p className="frame2_txt">
                Your lungs help you breathe, taking in fresh air and pushing out the old. 
                When you take a deep breath, you might hear a gentle whooshing sound, like the wind rustling through leaves.
              </p>
          </div> 
        </div>
        <div id="stomach_frame" className="frame_section"> 
          <img className="frame_image" src="assets/StomachFrame.png" alt='StomachFrame'/>
          <div className="frame3_body">
            <p className="frame3_title">Stomach</p>
              <p className="frame3_txt">
                Your stomach is a special organ. It's like a food processor, working hard to digest what you eat. 
                Sometimes, it might make gurgling sounds - that's just your stomach doing its job and turning food into energy.
              </p>
          </div> 
        </div>
        <div id="intestines_frame" className="frame_section"> 
          <img className="frame_image" src="assets/IntestinesFrame.png" alt='IntestinesFrame'/>
          <div className="frame4_body">
            <p className="frame4_title">Intestines</p>
              <p className="frame4_txt">
                Your intestines are like long pipes, and they sometimes make sounds too. 
                They help your body absorb the good stuff from your food and get rid of the stuff you don't need.
              </p>
          </div> 
        </div>
        <div id="last_frame" className="frame_section"> 
          <img className="frame_image" src="assets/LastInternalFrame.png" alt='LastInternalFrame'/>
          <div className="last_frame_body">
              <p className="last_frame_txt">
                These sounds are all part of your body's way of working, and they're usually a good sign! 
                So, don't worry about these sounds; they mean your body is keeping everything running smoothly. 
              </p>
          </div> 
        </div>
      </div> : ''
      } 
      { showExternal? 
      <div id="external_organs" style={{display: 'block'}}>

        <div id="nose_frame" className="frame_section"> 
          <img className="frame_image" src="assets/NoseFrame.png" alt='NoseFrame'/>
          <div className="left_frame_body">
            <p className="left_frame_title">Nose</p>
            <p className="left_frame_txt">
              Sometimes when we're fast asleep, it might produce a sound called snoring. 
              It's like a gentle melody created by our nose while we're in dreamland.
            </p>
          </div> 
        </div>
        <div id="mouth_frame" className="frame_section"> 
          <img className="frame_image" src="assets/MouthFrame.png" alt="back_img2"/>
          <div className="right_frame_body">
            <p className="right_frame_title">Mouth</p>
              <p className="right_frame_txt">
                Our mouth is not just for talking and eating; it's also a fantastic instrument! 
                <br/>
                It can produce all sorts of sounds like singing, 
                smacking, and even yelling when we're playing and having fun.
              </p>
          </div> 
        </div>
        <div id="hands_frame" className="frame_section"> 
          <img className="frame_image" src="assets/HandsFrame.png" alt='HandsFrame'/>
          <div className="left_frame_body">
            <p className="left_frame_title">Hands</p>
              <p className="left_frame_txt">
                Our hands are excellent at making noise too!
              <br/>
                They can express excitement through clapping, 
                celebrating achievements, 
                and sharing joy with high fives. 
              </p>
          </div> 
        </div>
        <div id="feet_frame" className="frame_section"> 
          <img className="frame_image" src="assets/FeetFrame.png" alt='FeetFrame'/>
          <div className="right_frame_body">
            <p className="right_frame_title">Feet</p>
              <p className="right_frame_txt">
                Our feet can join the musical ensemble. 
                They are talented at tapping, creating rhythmic beats on the floor. 
                It's like having a dance party whenever we're moving around!
              </p>
          </div> 
        </div>
        <div id="last_external_frame" className="frame_section"> 
          <img className="frame_image" src="assets/LastExternalFrame.png" alt='LastExternalFrame'/>
          <div className="last_frame_body">
              <p className="last_frame_txt">
                So, there you have it – the "Noisy Organs" edition featuring the mouth, nose, hands, and feet. 
                Each part adds its unique sound to the symphony of our daily lives.
              </p>
          </div> 
        </div>

      </div> : ''
      }

    </div>
    </>
  )
}

export default NoisyOrgans