import React from "react";

const CustomNav = (props) => {
  const { li, window } = props

  let openClose = () => {
    if (window === false) {
      props.setWindow(true);
    } else {
      props.setWindow(false);
    }
  };
  
  const onButtonClick = () => {
      localStorage.removeItem('user')
      props.setLoggedIn(false)
  }

  return (
    <nav className="navbar-menu" style={{ width: props.window === false ? 230 : 60 }}>
      <div className="menu" onClick={() => openClose()}>
        <img src="assets/menu.svg" alt="menu" />
      </div>
      <ul className="navbar__list">
        {li.map((item, i) => (
          <div className="navbar__li-box" key={i}>
            <a href={item[2]}>
              <img
                src={item[1]}
                alt={item[1]}
                style={{ paddingTop:8, paddingLeft: props.window === false ? 27 : 17 }}
              />
              <li
                className="navbar__li"
                style={{ display: props.window === false ? "inline-block" : "none" }}
              > 
                {item[0]}
              </li>
            </a>
          </div>
        ))}
        <div className="navbar__li-box" key={li.Count+1}>
            <a href={'/login'} onClick={onButtonClick}>
              <img src={"assets/exit.svg"} alt='exit.svg' style={{ paddingTop:10, paddingLeft: props.window === false ? 27 : 17 }} />
              <li className="navbar__li" style={{ display: props.window === false ? "inline-block" : "none" }} > 
                {'logout'}
              </li>
            </a>
          </div>
      </ul>
    </nav>
  );
};

export default CustomNav;
